/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from 'react'
import {
  PAGES,
  emitter,
  RESET_FORM,
  extractQueryParams,
  useToggle,
  useMount,
  ApiErrors,
} from 'resources'
import { usePage, useFormData, useErrorHandler } from 'contexts'
import { useForm } from 'react-hook-form'

import { ME, CREATE_USER } from './graphql'
import { useApolloClient, useMutation } from '@apollo/client'
import { isNil, mergeLeft } from 'ramda'
import { useAlert } from 'ui'
import { useTab } from '../../contexts/tab'

import { useNavigate, useLocation } from 'react-router-dom'
import { useLogin } from '../login/use-login'

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY

export const usePersonalData = (props = {}) => {
  const client = useApolloClient()
  const [unexpectedError, toggleUnexpectedError] = useToggle()
  const [checkingUser, setCheckingUser] = useState(false)
  const { setPage } = usePage()
  const { formData, setFormData } = useFormData()
  const { loginData, queryUserData } = useLogin()
  const { reset, register, handleSubmit, errors, watch, setValue } = useForm()
  const [alert, showAlert, closeAlert] = useAlert()
  const [createUserMutation] = useMutation(CREATE_USER)
  const { setActiveTab } = useTab()
  const { setIsError } = useErrorHandler()
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const { search, pathname } = useLocation()

  const currentParams = new URLSearchParams(search).toString()
  const createNewUser = useCallback(async (data, token) => {
    const input = {
      name: data.name,
      surname: data.surname,
      email: data.email,
      phone: data.phone,
      password: data.password,
      recaptchaToken: token,
      origin: 'CHECKOUT',
      params: {
        urlParams: search,
        formType: 'new',
        pathName: pathname,
        host: window.location.host,
      },
    }

    try {
      await createUserMutation({ variables: { input } })
    } catch (e) {
      if (e?.graphQLErrors && e?.graphQLErrors[0]?.code === ApiErrors.EMAIL_ALREADY_IN_USE) {
        showAlert(
          <> Este email já possui cadastro. <br />
            Clique
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault()
                setActiveTab('2')
              }}
            > aqui
            </a> para fazer login
          </>)
      } else {
        showAlert(e.message)
      }
      return false
    }
    return true
  }, [search, pathname, createUserMutation, showAlert, setActiveTab])

  const onSubmit = useCallback(async data => {
    closeAlert()
    setLoading(true)
    const { grecaptcha } = window

    grecaptcha.ready(async () => {
      const captchaToken = await grecaptcha.execute(RECAPTCHA_KEY, { action: 'submit' })

      const result = await createNewUser(data, captchaToken)
      if (result) {
        const newToken = await loginData({ email: data.email, password: data.password, sendData: true })
        if (newToken) {
          const persData = await queryUserData(newToken)
          if (persData) {
            setFormData(mergeLeft({
              personalData: persData,
            }))
          } else {
            setLoading(false)
          }
        } else {
          setLoading(false)
        }
        setCheckingUser(false)
        setPage(PAGES.payment)
        setLoading(false)
        navigate(`/payment?${currentParams}`)
      } else {
        setLoading(false)
      }
    })
  }, [closeAlert, createNewUser, loginData, setPage, navigate, currentParams, queryUserData, setFormData])

  const queryUserDataPersonal = useCallback(async () => {
    sessionStorage.setItem('proftkn', JSON.stringify(queryParams.token))
    const headers = { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('proftkn'))}` }

    try {
      const { data } = await client.query({
        query: ME,
        context: { headers },
      })
      setFormData(mergeLeft({
        personalData: data.me,
        haveRecentTransaction: data.haveRecentTransaction,
      }))
      setPage(PAGES.payment)
      setLoading(false)
      navigate(`/payment?${currentParams}`)
    } catch (e) {
      setLoading(false)
      setIsError({ Error: true, code: '126' })
    }
  }, [client, currentParams, navigate, setFormData, setIsError, setPage])

  const handleBack = useCallback(e => {
    e.preventDefault()
    setPage(PAGES.plan)
    navigate('/')
  }, [navigate, setPage])

  useMount(() => {
    if (queryParams.token) {
      queryUserDataPersonal()
    }

    emitter.on(RESET_FORM, () => reset({}))
  })

  return {
    register,
    handleSubmit,
    onSubmit,
    errors,
    watch,
    setValue,
    formData,
    handleBack,
    checkingUser,
    readonly: !isNil(queryParams.token),
    alert,
    closeAlert,
    toggleUnexpectedError,
    unexpectedError,
    loading,
  }
}

const queryParams = extractQueryParams()
