import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { usePage, useFormData } from 'contexts'
import { useMutation } from '@apollo/client'
import {
  cardMask,
  creditCard,
  creditCardExpiration,
  PAGES,
  createPagarmeClient,
  getPlanValue,
  formatValue,
  createContextWithToken,
  ApiErrors,
  emitter,
  RESET_FORM,
} from 'resources'
import { isEmpty, isNil, pipe, ifElse, always, prop, mergeLeft, replace, prepend } from 'ramda'
import { makeArray, mapI } from 'resources/composable'
import { PaymentTypes, PaymentStatus } from 'resources/types/payment'

import { FINISH_CHECKOUT } from './graphql'
import { CouponStatus } from 'resources/types/plans'
import { useAlert } from 'ui'

import { useNavigate, useLocation } from 'react-router-dom'

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY

export const useCard = () => {
  const [finishing, setFinishing] = useState(false)
  const { page, setPage, setCompletedPages } = usePage()
  const { formData, setFormData, typeOfProduct } = useFormData()
  const [alert, showAlert, closeAlert] = useAlert()
  const { register, reset, handleSubmit, errors } = useForm()
  const [cardFlag, setCardFlag] = useState('')
  const [cardLength, setCardLength] = useState('19')
  const [cvvLength, setCvvLength] = useState('3')
  const [finishMutation] = useMutation(FINISH_CHECKOUT)
  const { search, pathname } = useLocation()

  const navigate = useNavigate()

  const processPaymentSuccess = useCallback((installments, status) => {
    setFormData(mergeLeft({
      paymentResult: {
        type: PaymentTypes.CREDIT_CARD,
        status,
        installments,
      },
    }))

    setCompletedPages(mergeLeft({
      [page]: true,
    }))

    setPage(PAGES.confirm)
    navigate('/')
  }, [setFormData, setCompletedPages, page, setPage, navigate])

  const finishPayment = useCallback(data => async token => {
    try {
      const cardData = toPagarmeCard(data)
      const pagarmeClient = await createPagarmeClient()
      const cardHash = await pagarmeClient.security.encrypt(cardData)
      const input = transformPaymentInfos(token, data, cardHash, formData, typeOfProduct, data.cpf, search, pathname)
      const { data: result } = await finishMutation({
        variables: { input },
        context: createContextWithToken(JSON.parse(sessionStorage.getItem('proftkn'))),
      })
      if (result.data.success) {
        // console.log('==========> CARTÃO PAGARME ID: ', result.data.pagarmeId)
        processPaymentSuccess(data.installments, result.data.status)
        setFinishing(false)
        return
      }

      if (result.data.status === PaymentStatus.REJECTED) {
        showAlert('Compra não autorizada pela operadora do cartão. Por favor, verifique os dados do cartão e tente novamente.')
      }
    } catch (e) {
      if (e?.graphQLErrors && e?.graphQLErrors[0]?.code === ApiErrors.CPF_ALREADY_IN_USE) {
        showAlert('Este CPF já está sendo utilizado com um e-mail diferente.')
      } else {
        if (e?.graphQLErrors && e?.graphQLErrors[0]?.code === ApiErrors.INVALID_COUPON) {
          showAlert('Cupom inválido.')
        } else {
          showAlert('Ocorreu um erro inesperado durante a compra, por favor tente novamente em alguns minutos.')
        }
      }
    }
  }, [formData, typeOfProduct, search, pathname, finishMutation, processPaymentSuccess, showAlert])

  const onSubmit = useCallback(async data => {
    setFinishing(true)
    const { grecaptcha } = window

    grecaptcha.ready(() => {
      grecaptcha.execute(RECAPTCHA_KEY, { action: 'submit' })
        .then(finishPayment(data))
        .then(() => setFinishing(false))
        .catch(console.error)
    })
  }, [finishPayment])

  const cardType = useCallback(e => {
    const number = e.target.value
    const cardInformation = creditCard(e.target.value)
    const cardType = cardInformation?.card?.type
    const cardCVVLength = cardInformation.card?.code?.size

    setCardFlag(cardType)
    setCardLength('19')
    setCvvLength(3)
    e.target.value = cardMask(number, cardType)

    if (cardCVVLength) {
      setCvvLength(cardCVVLength)
    }

    if (cardType === 'american-express') {
      setCardLength('17')
    }

    if (cardType === 'diners-club') {
      setCardLength('16')
    }

    if (cardType === 'discover') {
      setCvvLength(4)
    }
  }, [setCardLength, setCardFlag, setCvvLength])

  const cardIsValid = useCallback(number => {
    const cardInformation = creditCard(number)

    if (!cardInformation.isValid) {
      return
    }

    return true
  }, [])

  useEffect(() => {
    if (formData.selectedPlan) {
      setFormData((previousValue) => {
        const installments = (previousValue.selectedInstallment &&
          previousValue.selectedInstallment.installment <= formData.selectedPlan.numberOfInstallments
        ) ? previousValue.selectedInstallment.installment : formData.selectedPlan.numberOfInstallments

        return ({
          ...previousValue,
          selectedInstallment: {
            installment: installments,
            value: calculateInstallmentValue(formData.selectedPlan, installments),
            originalValue: calculateOriInstallmentValue(formData.selectedPlan, installments),
          },
        })
      })
    }
  }, [formData.selectedPlan, setFormData])

  const handleChangeInstallments = useCallback((e) => {
    setFormData((previousValue) => ({
      ...previousValue,
      selectedInstallment: {
        installment: e.target.value,
        value: calculateInstallmentValue(formData.selectedPlan, e.target.value),
        originalValue: calculateOriInstallmentValue(formData.selectedPlan, e.target.value),
      },
    }))
  }, [formData.selectedPlan, setFormData]
  )

  const dateIsValid = useCallback(date => {
    const isValidDate = creditCardExpiration(date)

    if (!isValidDate.isValid) {
      return
    }

    return true
  }, [])

  const handleBack = useCallback(e => {
    e.preventDefault()
    setPage(PAGES.personalData)
    navigate('/personal')
  }, [navigate, setPage])

  useEffect(() => {
    emitter.on(RESET_FORM, () => { reset({}) })
  }, [reset])

  return {
    cardIsValid,
    dateIsValid,
    cardType,
    cardLength,
    cvvLength,
    register,
    handleSubmit,
    onSubmit,
    errors,
    cardFlag,
    handleBack,
    installmentOptions: createInstallmentOptions(formData.selectedPlan),
    finishing,
    closeAlert,
    alert,
    handleChangeInstallments,
  }
}

const planIsEmpty = plan => isEmpty(plan) || isNil(plan)

const calculateInstallmentValue = (plan, installment) => {
  const planValue = getPlanValue(plan).raw / installment

  return formatValue(planValue.toFixed(2)).formatted
}

const calculateOriInstallmentValue = (plan, installment) => {
  const planValue = plan.totalValue.raw / installment

  return formatValue(planValue.toFixed(2)).formatted
}

const createInstallmentOptions = plan => ifElse(
  planIsEmpty,
  always([]),
  pipe(
    prop('numberOfInstallments'),
    makeArray,
    mapI((_, index) =>
      ({
        label: `${index + 1}x de ${calculateInstallmentValue(plan, index + 1)} sem juros`,
        value: index + 1,
      })
    ),
    prepend({ label: 'Selecione o número de parcelas', value: '' })
  )
)(plan)

const transformPaymentInfos = (recaptchaToken, paymentData, cardHash, formData, typeOfProduct, cpf, search, pathname) => {
  const { id: planId, couponStatus, couponName } = formData.selectedPlan
  const hasCoupon = couponStatus === CouponStatus.APPLIED

  return {
    planId,
    paymentMethod: 'CREDIT_CARD',
    coupon: hasCoupon ? couponName : null,
    creditCardData: {
      cardHash,
      numberOfInstallments: Number(paymentData.installments),
      recaptchaToken,
    },
    typeOfProduct,
    cpf,
    params: {
      urlParams: search,
      formType: 'checkout',
      pathName: pathname,
      host: window.location.host,
    },
  }
}

const normalizeCardInfo = pipe(
  replace(/\s/g, ''),
  replace('/', '')
)

const toPagarmeCard =
  ({
    cardNumber,
    cardHolderName,
    cvv,
    cardExpirationDate,
  }) => ({
    card_number: normalizeCardInfo(cardNumber),
    card_holder_name: cardHolderName,
    card_expiration_date: normalizeCardInfo(cardExpirationDate),
    card_cvv: cvv,
  })
