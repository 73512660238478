import { gql, useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { usePage, useFormData } from 'contexts'
import { PAGES, createContextWithToken, getPlanValue, ApiErrors } from 'resources'

import { PaymentTypes } from 'resources/types/payment'
import { mergeLeft } from 'ramda'
import { useAlert } from 'ui'
import { useState } from 'react'
import { CouponStatus } from 'resources/types/plans'

import { useNavigate, useLocation } from 'react-router-dom'

export const usePix = () => {
  const [loading, setLoading] = useState(false)
  const [finishMutation] = useMutation(FINISH_CHECKOUT)
  const { page, setPage, setCompletedPages } = usePage()
  const { formData, setFormData, typeOfProduct } = useFormData()
  const { register, handleSubmit, errors } = useForm()
  const [alert, showAlert, closeAlert] = useAlert()
  const { search, pathname } = useLocation()

  const navigate = useNavigate()

  const onSubmit = async data => {
    closeAlert()
    setLoading(true)
    const input = transformPaymentInfos(formData, typeOfProduct, data.cpf, search, pathname)

    try {
      const { data: result } = await finishMutation({
        variables: { input },
        context: createContextWithToken(JSON.parse(sessionStorage.getItem('proftkn'))),
      })
      if (result.data.success) {
        const paymentResult = {
          id: result.data.id,
          type: PaymentTypes.PIX,
          qrCode: result.data.qrCode,
          qrCodeImage: result.data.qrCodeImage,
        }

        if (formData?.selectedPlan?.couponStatus === CouponStatus.APPLIED && formData?.selectedPlan?.valueWithCoupon?.raw === 0) {
          paymentResult.type = PaymentTypes.COUPON
          paymentResult.status = 'APPROVED'
          delete paymentResult.qrCode
          delete paymentResult.qrCodeImage
        }
        setFormData(mergeLeft({ paymentResult }))

        setCompletedPages(completedPages => ({
          ...completedPages,
          [page]: true,
        }))

        setPage(PAGES.confirm)
        navigate('/')
      } else {
        showAlert('Ocorreu um erro ao tentar gerar o PIX, por favor tente novamente em alguns minutos.')
      }
    } catch (e) {
      if (e?.graphQLErrors && e?.graphQLErrors[0]?.code === ApiErrors.INVALID_COUPON) {
        showAlert('Cupom inválido.')
      } else {
        showAlert('Ocorreu um erro inesperado durante a compra, por favor tente novamente em alguns minutos.')
      }
    }
    setLoading(false)
  }

  const handleBack = e => {
    e.preventDefault()
    setPage(PAGES.personalData)
    navigate('/personal')
  }

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    handleBack,
    loading,
    alert,
    totalValue: formData.selectedPlan ? formData.selectedPlan.valueWithPixDiscount ? formData.selectedPlan.valueWithPixDiscount : getPlanValue(formData.selectedPlan) : {},
  }
}

const transformPaymentInfos = (formData, typeOfProduct, cpf, search, pathname) => {
  const { id: planId, couponStatus, couponName } = formData.selectedPlan
  const hasCoupon = couponStatus === CouponStatus.APPLIED

  return {
    planId,
    paymentMethod: 'PIX',
    coupon: hasCoupon ? couponName : null,
    typeOfProduct,
    cpf,
    params: {
      urlParams: search,
      formType: 'checkout',
      pathName: pathname,
      host: window.location.host,
    },
  }
}

export const FINISH_CHECKOUT = gql`
  mutation FinishCheckout($input: FinishCheckoutInput!){
    data: finishCheckout(input: $input) {
      id
      success
      qrCode,
      qrCodeImage,
      pagarmeId
    }
  }
`
